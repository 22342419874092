import api from "@/api";

const tenant = {
  create(data) {
    return api.post(`/tenant`, data);
  },
  list(params) {
    return api.get(`/tenant`, {
      params: params,
    });
  },
  get(pk) {
    return api.get(`/tenant/${pk}`);
  },
  update(pk, data) {
    return api.patch(`/tenant/${pk}`, data);
  },
  listUser(pk, params) {
    return api.get(`/tenant/${pk}/user`, {
      params: params,
    });
  },
  changeUserRole(pk, data) {
    return api.patch(`/tenant-user/${pk}/role`, data);
  },
  setUserInviteCode(pk, data) {
    return api.patch(`/tenant-user/${pk}/invite-code`, data);
  },
  checkUserInviteCode(data) {
    return api.post(`/invite-code/check`, data);
  },
  createApplication(pk, data) {
    return api.post(`/tenant/${pk}/application`, data);
  },
  listApplication(pk) {
    return api.get(`/tenant/${pk}/application`);
  },
  getApplication(pk) {
    return api.get(`/tenant-application/${pk}`);
  },
  changeApplication(pk, data) {
    return api.patch(`/tenant-application/${pk}`, data);
  },
};

export default tenant;
