<template>
  <div id="d-tenant">
    <el-dialog
      :title="title"
      :visible.sync="visible"
      @closed="handleClosed"
      width="400px"
    >
      <el-form
        :model="form"
        ref="form"
        label-width="80px"
        label-position="left"
      >
        <el-form-item label="租户名称">
          <el-input
            size="small"
            type="text"
            v-model="form.name"
            placeholder="租户名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="运营人员" v-if="action === 'create'">
          <el-select style="width: 100%" size="small" v-model="form.member_id">
            <el-option
              label="a666-小米"
              value="25a0f48e454721254d349000df24727a"
            ></el-option>
            <el-option
              label="a999-米乐"
              value="664d2856a9b14a559dc505c8ca5daee2"
            ></el-option>
            <el-option
              label="a888-小乐"
              value="db65badd2ad263b601de3d2ed0b999c7"
            ></el-option>
            <el-option
              label="a333-小红"
              value="c1ac87a52064d732fb3e5951f434b9ba"
            ></el-option>
            <el-option
              label="a111-琦琦"
              value="7786566809438717f48576bcb23ab853"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="租户图片">
          <c-image-upload
            :image="form.icon_url"
            @change="handleImageChange"
          ></c-image-upload>
        </el-form-item>
        <el-form-item label="邀请码" v-if="action === 'create'">
          <el-input
            size="small"
            type="text"
            v-model="form.invite_code"
            placeholder="邀请码"
            ><el-button slot="append" @click="handleCheckTenantUserInviteCode"
              >检测</el-button
            ></el-input
          >
        </el-form-item>
        <el-form-item label="是否添加邀请人为好友" v-if="action === 'create'">
          <el-switch
            v-model="form.is_add_inviter"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="1"
            :inactive-value="-1"
          ></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="handleCancel">取 消</el-button>
        <el-button size="small" type="primary" @click="handleOk('form')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import tenant from "@/api/tenant";

import CImageUpload from "@/components/CImageUpload";

export default {
  name: "d-tenant",
  data() {
    return {
      visible: false,
      form: {
        name: "",
        user_id: "",
        member_id: "",
        icon_url:
          "https://am-lll-open.oss-cn-hangzhou.aliyuncs.com/tenant-icon.png",
        invite_code: "",
        is_add_inviter: 1,
      },
      title: "开通租户",
      action: "create",
    };
  },
  components: {
    CImageUpload,
  },
  methods: {
    handleAdd(val) {
      this.visible = true;
      this.action = "create";
      this.title = "开通租户";
      this.form.user_id = val;
      this.form.invite_code = this.handleRandomCode(4);
    },
    handleEdit(pk) {
      this.action = "edit";
      this.title = "编辑租户";
      tenant.get(pk).then((res) => {
        this.visible = true;
        this.form = res.results;
      });
    },
    handleRandomCode(length) {
      let strHead = "ABCDEFGHIJKLMNPQRSTUVWXYZ";
      let strBack = "0123456789";

      var result = "";
      for (var i = length - 1; i > 0; --i)
        result += strBack[Math.floor(Math.random() * strBack.length)];

      return strHead[Math.floor(Math.random() * strHead.length)] + result;
    },
    handleCheckTenantUserInviteCode() {
      let data = {
        code: this.form.invite_code.toUpperCase(),
      };
      tenant
        .checkUserInviteCode(data)
        .then(() => {
          this.$message({
            type: "success",
            message: "可以使用！",
          });
        })
        .catch((err) => {
          this.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    handleImageChange(val) {
      this.form.icon_url = val;
    },
    handleOk(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.action === "create") {
            tenant
              .create(this.form)
              .then(() => {
                this.visible = false;
                this.$message({
                  type: "success",
                  message: "开通成功！",
                });
              })
              .catch((err) => {
                this.$message({
                  type: "warning",
                  message: err.message,
                });
              });
          } else {
            let pk = this.form.pk;
            let data = this.form;
            tenant
              .update(pk, data)
              .then(() => {
                this.visible = false;
                this.$message({
                  type: "success",
                  message: "编辑成功！",
                });
                this.$emit("change", true);
              })
              .catch((err) => {
                this.$message({
                  type: "warning",
                  message: err.message,
                });
              });
          }
        }
      });
    },
    handleCancel() {
      this.visible = false;
    },
    handleClosed() {
      this.form = {
        name: "",
        user_id: "",
        member_id: "",
        icon_url:
          "https://xt-miquan-open.oss-cn-hangzhou.aliyuncs.com/tenant-icon.png",
        invite_code: "",
        is_add_inviter: 1,
      };
      this.$refs.form.resetFields();
    },
  },
};
</script>
