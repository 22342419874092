<template>
  <div id="d-user-reset-password">
    <el-dialog
      title="重置密码"
      :visible.sync="visible"
      @closed="handleClosed"
      width="400px"
    >
      <el-form
        :model="form"
        ref="form"
        label-width="80px"
        label-position="left"
      >
        <el-form-item label="新密码">
          <el-input
            size="small"
            v-model="form.password"
            :controls="false"
            @change="handleChange"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="handleCancel">取 消</el-button>
        <el-button
          size="small"
          type="danger"
          @click="handleOk"
          :disabled="data.result_state > 1"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import user from "@/api/user";

export default {
  name: "d-user-reset-password",
  data() {
    return {
      visible: false,
      data: {},
      form: {
        password: "as123456",
      },
    };
  },
  methods: {
    handleCheckValue(min, max, val, len) {
      if (parseInt(min) > val) {
        return false;
      }
      if (parseInt(max) < parseInt(val)) {
        return false;
      }
      return val.length === len;
    },
    handleChange() {
      if (this.form.password.length < 5) {
        this.$confirm(this.form.password, "格式错误", {
          confirmButtonText: "关闭",
          type: "warning",
        }).then(() => {
          this.visible = false;
        });
      }
    },
    handleShow(data) {
      this.data = data;
      this.visible = true;
    },
    handleOk() {
      this.$confirm("确认重置 " + this.data.nickname + " 的密码", "提示", {
        confirmButtonText: "是的",
        cancelButtonText: "不是",
        type: "warning",
      }).then(() => {
        user
          .resetPassword(this.data.pk, this.form)
          .then(() => {
            this.visible = false;
            this.$message({
              type: "success",
              message: "操作成功！",
            });
            this.$emit("change", true);
          })
          .catch((err) => {
            this.$message({
              type: "warning",
              message: err.message,
            });
            this.visible = false;
          });
      });
    },
    handleCancel() {
      this.visible = false;
    },
    handleClosed() {
      this.visible = false;
    },
  },
};
</script>
