<template>
  <div id="c-image-upload">
    <el-upload
      class="uploader"
      :limit="1"
      :action="setURL()"
      :headers="setHeaders()"
      :show-file-list="false"
      :on-success="handleUploadSuccess"
      :on-error="handleUploadError"
      :disabled="disabled"
    >
      <img v-if="url" :src="url" class="image" />
      <i v-else class="el-icon-plus uploader-icon"></i>
    </el-upload>
  </div>
</template>

<script>
export default {
  name: "c-image-upload",
  data() {
    return {
      url: "",
    };
  },
  created() {
    this.handleInit();
  },
  props: {
    image: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleInit() {
      this.url = this.image;
    },
    handleUploadSuccess(response) {
      this.$emit("change", response.results.url);
      this.url = response.results.url;
    },
    handleUploadError() {
      this.$message({
        type: "warning",
        message: "上传失败！",
      });
    },
    handleClear() {
      this.url = "";
    },
    setURL() {
      return `${process.env.VUE_APP_API_ROOT}/upload`;
    },
    setHeaders() {
      return {
        Authorization: `Bearer ${localStorage.getItem("auth.token")}`,
        application: "mq-admin-pc",
      };
    },
  },
  watch: {
    image: function () {
      this.handleInit();
    },
  },
};
</script>

<style lang="stylus">
#c-image-upload
  .uploader .el-upload
    border 1px dashed #d9d9d9
    border-radius 6px
    cursor pointer
    position relative
    overflow hidden

  .uploader .el-upload:hover
    border-color #409eff

  .uploader-icon
    font-size 28px
    color #8c939d
    width 150px
    height 150px
    line-height 150px !important
    text-align center

  .image
    width 150px
    height 150px
    display block
</style>
