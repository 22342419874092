<template>
  <div id="user" v-loading="loading">
    <div class="cm-content">
      <h-user :params="params" @change="handleSearch"></h-user>
      <br />
      <el-table :data="data">
        <el-table-column label="头像" width="80">
          <template slot-scope="scope">
            <el-image
              v-if="scope.row.avatar_url"
              :src="scope.row.avatar_url"
              :preview-src-list="[scope.row.avatar_url]"
              class="avatar"
            >
            </el-image>
            <div v-else>--</div>
          </template>
        </el-table-column>
        <el-table-column label="mqid" prop="miquan_id"></el-table-column>
        <el-table-column label="uid" prop="user_id"></el-table-column>
        <el-table-column label="手机号码" prop="cellphone"> </el-table-column>
        <el-table-column label="昵称" prop="nickname">
          <template slot-scope="scope">
            {{ scope.row.nickname | formatNullValue }}
          </template>
        </el-table-column>
        <el-table-column label="所属租户" prop="tenant_name"></el-table-column>
        <el-table-column label="当前版本" prop="version">
          <template slot-scope="scope">
            {{ scope.row.version | formatNullValue }}
          </template>
        </el-table-column>
        <el-table-column label="系统平台" prop="platform">
          <template slot-scope="scope">
            {{ scope.row.platform | formatNullValue }}
          </template>
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            {{ scope.row.state | formatState }}
          </template>
        </el-table-column>
        <el-table-column label="注册时间">
          <template slot-scope="scope">
            {{ scope.row.created_at | formatDateTime }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="460px">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.state === -1"
              size="mini"
              type="primary"
              @click="handleChangeState(scope.row.pk, { state: 1 })"
            >
              解封
            </el-button>
            <el-button
              v-if="scope.row.state === 0"
              size="mini"
              type="warning"
              disabled
            >
              封号
            </el-button>
            <el-button
              v-if="scope.row.state === 1"
              size="mini"
              type="warning"
              @click="handleChangeState(scope.row.pk, { state: -1 })"
            >
              封号
            </el-button>
            <el-button
              size="mini"
              type="danger"
              @click="handleResetPassword(scope.row)"
            >
              重置密码
            </el-button>
            <el-button
              :disabled="scope.row.state === 0"
              size="mini"
              type="primary"
              @click="handleCreateTenant(scope.row.user_id)"
              >开通租户</el-button
            >
            <el-button
              size="mini"
              type="warning"
              @click="handleChangeTenant(scope.row.pk)"
              >更换租户</el-button
            >
            <el-button size="mini" @click="handleClearLoginLimit(scope.row.pk)"
              >解除登录限制</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <br />
      <el-row type="flex" justify="center">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="10"
          :total="total"
        >
        </el-pagination>
      </el-row>
    </div>
    <d-user-reset-passowrd ref="dialogResetPassword"></d-user-reset-passowrd>
    <d-tenant ref="dialogTenant"></d-tenant>
  </div>
</template>

<script>
import HUser from "@/components/headers/HUser";
import DUserResetPassowrd from "@/components/dialogs/DUserResetPassowrd";
import DTenant from "@/components/dialogs/DTenant";

import user from "@/api/user";

export default {
  name: "user",
  data() {
    return {
      loading: false,
      data: [],
      params: {},
      total: 0,
      currentPage: 1,
    };
  },
  created() {
    this.handleInit(this.$route);
    this.handleList(this.params);
  },
  components: {
    HUser,
    DUserResetPassowrd,
    DTenant,
  },
  methods: {
    handleInit(route) {
      if (route.query.page) {
        this.params["page"] = route.query.page;
      }
      if (route.query.keyword) {
        this.params["keyword"] = route.query.keyword;
      }
    },
    handleList(params) {
      user.list(params).then((res) => {
        this.data = res.results;
        this.total = res.count;
        this.loading = false;
        this.currentPage = parseInt(this.$route.query.page);
      });
    },
    handleResetPassword(data) {
      this.$refs.dialogResetPassword.handleShow(data);
    },
    handleChangeState(pk, data) {
      this.$confirm("是否处理？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          user
            .changeState(pk, data)
            .then(() => {
              this.handleList(this.params);
              this.$message({
                type: "success",
                message: "操作成功！",
              });
            })
            .catch((err) => {
              this.$message({
                type: "warning",
                message: err.message,
              });
            });
        })
        .catch(() => {});
    },
    handleCreateTenant(val) {
      this.$refs.dialogTenant.handleAdd(val);
    },
    handleChangeTenant(pk) {
      this.$prompt("租户ID", "修改租户", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(({ value }) => {
        let data = {
          tenant_id: value,
        };
        user
          .changeTenant(pk, data)
          .then(() => {
            this.$message({
              type: "success",
              message: "操作成功！",
            });
          })
          .catch((err) => {
            this.$message({
              type: "warning",
              message: err.message,
            });
          });
      });
    },
    handleClearLoginLimit(pk) {
      this.$confirm("是否处理？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          user
            .clearLoginLimit(pk)
            .then(() => {
              this.$message({
                type: "success",
                message: "操作成功！",
              });
            })
            .catch((err) => {
              this.$message({
                type: "warning",
                message: err.message,
              });
            });
        })
        .catch(() => {});
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.params["page"] = val;
      let params = Object.assign({}, this.params);
      this.$router
        .push({
          name: "user",
          query: params,
        })
        .catch(() => {});
    },
    handleSearch(val) {
      this.currentPage = 1;
      this.params = val;
      this.params.page = 1;
      this.$router
        .push({
          name: "user",
          query: val,
        })
        .catch(() => {});
    },
  },
  watch: {
    $route: {
      deep: true,
      handler: function (val) {
        if (val.name === "user") {
          this.handleInit(val);
          this.handleList(this.params);
        }
      },
    },
  },
};
</script>

<style lang="stylus" scope>
#user {
  display: flex;
  height: 100%;

  .avatar {
    width: 40px;
    height: 40px;
  }
}
</style>
