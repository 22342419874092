import api from "@/api";

const user = {
  list(params) {
    return api.get(`/user`, {
      params: params,
    });
  },
  changeState(pk, data) {
    return api.patch(`/user/${pk}/state`, data);
  },
  resetPassword(pk, data) {
    return api.patch(`/user/${pk}/reset-password`, data);
  },
  changeTenant(pk, data) {
    return api.patch(`/user/${pk}/tenant`, data);
  },
  clearLoginLimit(pk) {
    return api.delete(`/user/${pk}/login-limit`);
  },
};

export default user;
